html,
body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  background-color: whitesmoke;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.landing {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url(IMG_1024.jpg);
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-shadow: 10px 10px 100px black;
}

.landing-image {
  height: 100vh;
  object-fit: contain;
}

.image-container {
  position: relative;
  text-align: center;
  color: black;
}

.copy {
  width: 30vw;
  margin-top: 2em;
  padding: 2em;
  background-color: whitesmoke;
}

.banner-image {
  max-width: 981px;
}

h1.brianna {
  padding-top: 1em;
  text-transform: lowercase;
  font-size: 4em;
  text-align: center;
  font-weight: normal;
  margin-bottom: 0.5em;
}
.whitesmoke {
  text-shadow: 10px 10px 100px black;
  color: whitesmoke;
}

.black {
  color: black;
}

h2 {
  margin: 0 0 0.75em 0;
}

.column {
  max-width: 981px;
  background-color: white;
}

.about {
  margin: auto;
  height: 100vh;
}

.books {
  margin: auto;
  height: inherit;
}

.bio {
  padding: 0 10em 0 10em;
}

div.credits {
  padding: 10em 10em 0em 10em;
  font-size: 0.75em;
}

p.credits {
  text-align: center;
  margin: 0;
}

/* nav bar styles */

.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 2em;
}

a.main-nav-bar {
  color: white;
  text-transform: uppercase;
}
a.interior-nav-bar {
  color: black;
  text-transform: uppercase;
}

ul {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0px;
}
li {
  display: block;
  margin: 1rem;
}

a {
  text-decoration: none;
}
a:hover {
  color: grey;
}

/* individual page styles */
h1 {
  text-align: center;
}

p {
  margin: 1rem;
}

/* book page styles */

.book {
  text-emphasis: none;
  padding: 4em;
  display: flex;
  flex-direction: row;
}

.cover-div {
  height: 25rem;
  margin-bottom: 1rem;
}
.cover-image {
  height: 100%;
}

.book-copy {
  font-size: 1.1em;
  padding: 0 4em;
}
.pub-date {
  font-weight: 500;
  padding-top: 1em;
}

@media only screen and (max-width: 981px) {
  .banner-image {
    max-width: 100vw;
  }
  .bio {
    max-width: 75vw;
    padding: 0;
    margin: auto;
  }
  .column {
    max-width: 100vw;
  }
  .about {
    height: inherit;
  }
  .credits {
    padding: 0;
    font-size: 0.75em;
  }
}
@media only screen and (max-width: 700px) {
  .book {
    text-emphasis: none;
    padding: 1em 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .book-copy {
    padding: 0;
  }
  div.credits {
    padding: 0.5em;
  }
  .book {
    align-items: start;
  }
}

@media only screen and (max-width: 325px) {
  h1.brianna {
    font-size: 3em;
  }
  .book-copy {
    padding: 1em;
  }
  .book {
    padding: 0em;
  }
  div.bio {
    max-width: 100vw;
  }
  .cover-image {
    width: 100vw;
  }
  .nav-bar {
    padding-bottom: 1em;
  }
}
